import "./App.css"; // import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCoverflow,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function App() {
  return (
    <main>
      <header>
        <nav
          className="navbar navbar-expand-lg navbar-light fixed-top"
          id="mainNav"
        >
          <div className="container px-4 px-lg-5">
            <img className="img-brand" src="assets/img/icon.png" alt="" />
            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#about"
                  >
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#tokenomics">
                    Tokenomics
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container" style={{ marginTop: "3.5rem" }}>
          <br />
          <br />
          <br />
          <div className="glitch" data-text="Moonwolf">
            Moonwolf
          </div>
          <div className="glow">Moonwolf</div>
        </div>
      </header>
      <section className="about-section" id="about">
        <div className="container px-4 px-lg-5 pt-5">
          <div className="intro-title about-text qwetqwe">Moonwolf</div>
          <div className="about-info">
            Moonwolf is a generative collection 222 NFTs of the greatest Wolf.
            Moonwolf was born while browsing NFT projects and seeing things that
            we believe we can improve upon and do better in the NFT community.
            Moonwolf vision and plan with the project is to create long term
            utility by rewarding holders (passive income).
            <br />
            <br />
            Each Moonwolf collectible is 100% unique and randomly generated over
            70 variations from 8 traits. They include Background, Skin, Eyewear,
            Head, Teeth, Outfit, Necklace and Accessories. Some are rarer than
            others.
          </div>
        </div>
      </section>
      <section className="tokenomics-section" id="tokenomics">
        <div className="container px-4 px-lg-5">
          <div className="intro-title ncxajkc">Tokenomics..</div>
          <div className="progress pqlapqla">
            <div
              className="progress-bar bg-info"
              role="progressbar"
              style={{ width: "5%" }}
              aria-valuenow={5}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              5%
            </div>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: "10%" }}
              aria-valuenow={10}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              10%
            </div>
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              style={{ width: "10%" }}
              aria-valuenow={10}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              10%
            </div>
            <div
              className="progress-bar bg-dark"
              role="progressbar"
              style={{ width: "10%" }}
              aria-valuenow={10}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              10%
            </div>
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: "65%" }}
              aria-valuenow={65}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              65%
            </div>
          </div>
          <div className="text-white text-center">
            <p className="mt-4">Detail:</p>
            <button className="btn btn-info mt-2" type="button">
              AIRDROP
            </button>
            <button className="btn btn-success mt-2" type="button">
              LIQUIDITY
            </button>
            <button className="btn btn-primary mt-2" type="button">
              TEAM
            </button>
            <button className="btn btn-dark mt-2" type="button">
              PARTNERSHIP
            </button>
            <button className="btn btn-warning mt-2" type="button">
              STAKING
            </button>
          </div>
        </div>
      </section>
      <section className="collection-section">
        <div className="swiper-container">
          <div className="container px-4 px-lg-5">
            <div className="intro-title laksjdas">
              Sneak Peek Our Collections..
            </div>
          </div>
          <div className="container">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              coverflowEffect={{
                rotate: 30,
                stretch: 0,
                depth: 200,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              modules={[Autoplay, EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src="assets/img/0.png" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/1.png" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/2.png" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/3.png" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="assets/img/4.png" />
              </SwiperSlide>
            </Swiper>
            {/* <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img src="assets/img/0.png" />
            </div>
            <div className="swiper-slide">
              <img src="assets/img/1.png" />
            </div>
            <div className="swiper-slide">
              <img src="assets/img/2.png" />
            </div>
            <div className="swiper-slide">
              <img src="assets/img/3.png" />
            </div>
            <div className="swiper-slide">
              <img src="assets/img/4.png" />
            </div>
          </div> */}
          </div>
        </div>
      </section>
      <section className="timeline-section" id="roadmap">
        <div className="container px-4 px-lg-5">
          <div className="intro-title ncxajkc">Check Our Roadmap..</div>
          <div className="container-fluid blue-bg">
            <div className="container">
              <div className="row align-items-center how-it-works">
                <div className="col-2 text-center bottom">
                  <div className="circle">25%</div>
                </div>
                <div className="col-6 qpwieqqq">
                  <p>
                    <strong>Moonwolf Social Media ( Twitter, Discord) ✓</strong>{" "}
                    <br />
                    <strong>Moonwolf Website. ✓</strong>
                    <br />
                    <strong>Sneak peek Moonwolf NFTs. ✓</strong> <br />
                    <strong>
                      Collaboration with other project on Near Protocol. ✓
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row timeline">
            <div className="col-2">
              <div className="corner top-right"></div>
            </div>
            <div className="col-8">
              <hr />
            </div>
            <div className="col-2">
              <div className="corner left-bottom"></div>
            </div>
          </div>
          <div className="row align-items-center justify-content-end how-it-works">
            <div className="col-6 text-end qpwieqw">
              <div className="align-items-center">
                <strong className="hgsdfs">✓ Moonwolf NFTs launching.</strong>
              </div>
              <div className="align-items-center">
                <div
                  className="spinner-border ms-auto jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong className="hgsdfs">Minting Wolf NFTs</strong>
              </div>
              <div className="align-items-center">
                <div
                  className="spinner-border ms-auto jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong className="hgsdfs">$MOON token launching.</strong>
              </div>
              <div className="align-items-center">
                <div
                  className="spinner-border ms-auto jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong className="hgsdfs">List Wolf NFTs on market</strong>
              </div>
            </div>
            <div className="col-2 text-center full">
              <div className="circle">50%</div>
            </div>
          </div>
          <div className="row timeline">
            <div className="col-2">
              <div className="corner right-bottom"></div>
            </div>
            <div className="col-8">
              <hr />
            </div>
            <div className="col-2">
              <div className="corner top-left"></div>
            </div>
          </div>
          <div className="row align-items-center how-it-works">
            <div className="col-2 text-center top">
              <div className="circle">75%</div>
            </div>
            <div className="col-6 qpwieqqq">
              <div className="align-items-center">
                <strong className="hgsdfs">Wolf NFTs staking.</strong>
                <div
                  className="spinner-border jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
              <div className="align-items-center">
                <strong className="hgsdfs">Giveaways & Contest.</strong>
                <div
                  className="spinner-border jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
              <div className="align-items-center">
                <strong className="hgsdfs">Wolf Merchandise.</strong>
                <div
                  className="spinner-border jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
              <div className="align-items-center">
                <strong className="hgsdfs">Moonwolf Gen1 Sneak peek.</strong>
                <div
                  className="spinner-border jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            </div>
          </div>
          <div className="row timeline">
            <div className="col-2">
              <div className="corner top-right"></div>
            </div>
            <div className="col-8">
              <hr />
            </div>
            <div className="col-2">
              <div className="corner left-bottom"></div>
            </div>
          </div>
          <div className="row align-items-center justify-content-end how-it-works">
            <div className="col-6 text-end qpwieqw">
              <div className="align-items-center">
                <div
                  className="spinner-border ms-auto jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong className="hgsdfs">Moonwolf Traits Contest.</strong>
              </div>
              <div className="align-items-center">
                <div
                  className="spinner-border ms-auto jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong className="hgsdfs">Gen1 Minting use $MOON.</strong>
              </div>
              <div className="align-items-center">
                <div
                  className="spinner-border ms-auto jhcvxghxc"
                  role="status"
                  aria-hidden="true"
                ></div>
                <strong className="hgsdfs">Project improvment.</strong>
              </div>
            </div>
            <div className="col-2 text-center full">
              <div className="circle">100%</div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-section" id="faq">
        <div className="container px-4 px-lg-5">
          <div className="intro-title ncxajkc">
            Frequently Asked Questions..
          </div>
          <div className="faq-container">
            <div className="row">
              <div className="col-sm-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What is Moonwolf?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        MoonWolf is a generative collection 222 NFTs of the
                        greatest Wolf with 70+ different attributes. DireWolf
                        was born while browsing NFT projects and seeing things
                        that we believe we can improve upon and do better in the
                        NFT community.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How do I get a Moonwolf NFTs?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        You will be able to mint a Moonwolf NFTs on our minting
                        page, or purchase one after mint on the secondary
                        marketplaces once we have launched!
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What is the supply of Moonwolf?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Max supply is 222 Wolf with 70+ different attributes.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        What is the price?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">6 N</div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        What will i get by holding Moonwolf NFT?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Joining the MoonWolf community will let you enjoy the
                        long list of privileges, including earning $MOON Token.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        When is the drop ?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        The official public sale date will be announce in our
                        discord channel "Announcement" in the coming days.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        Who are the founders ?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        MoonWolf is a real-life friends project. We started
                        working together a few years ago on various projects.
                        <br />
                        We have complementary skills : Art & design, NFT and
                        crypto, blockchain developer and marketing.
                        <br />
                        We are now ready to launch a much more impactful project
                        : MoonWolf
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        Which wallet do I need to Mint a Moonwolf?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        You can only mint a MoonWolf with a Near Wallet.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        I have an issue, what should I do ?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        If you have any issue, please go to our discord in the
                        "Report issue" section. <br />
                        Disclaimer : It's important to keep in mind that if you
                        send your Near to a wrong address, the wrong network or
                        something related to Near transaction, nobody will be
                        able to refund you because blockchain is a decentralized
                        technology.
                        <br />
                        Also never trust someone who just send you a DM. Our
                        team will never DM you first.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item raesdreaw">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed lkhjlfgk"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        How to contact the team members ?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        To contact the team members just ping them in the
                        official discord server. Discord link is on our website,
                        twitter and all other social medias
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-4 px-lg-5" style={{ marginTop: "5rem" }}>
          <div className="intro-title ncxajkc">Lets meet our team..</div>
          <div className="row gx-4 gx-lg-5 quwgvdtwdasd">
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <img
                    className="img-fluid pqoweiqwe"
                    src="assets/img/Roderick.png"
                    alt="..."
                  />
                  <h4 className="text-uppercase m-0">Roderick</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <img
                    className="img-fluid pqoweiqwe"
                    src="assets/img/Bert Bailey.png"
                    alt="..."
                  />
                  <h4 className="text-uppercase m-0">Bert Bailey</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <img
                    className="img-fluid pqoweiqwe"
                    src="assets/img/Esmond Lane.png"
                    alt="..."
                  />
                  <h4 className="text-uppercase m-0">Esmond Lane</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0 iqwhdas">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <img
                    className="img-fluid pqoweiqwe"
                    src="assets/img/Joe Parsons.png"
                    alt="..."
                  />
                  <h4 className="text-uppercase m-0">Joe Parsons</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0 iqwhdas">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <img
                    className="img-fluid pqoweiqwe"
                    src="assets/img/Tyree.png"
                    alt="..."
                  />
                  <h4 className="text-uppercase m-0">Tyree</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="d-flex align-items-center align-content-center text-white footer-condition uaygduawba">
          <div className="text-center">Moonwolf@2022 All rights reserved</div>
          <div className="social set-ms">
            <div className="row"></div>
            <a className="mx-2" target="_blank" rel="noreferrer" href="/">
              <i className="fa-brands fa-discord"></i>
            </a>
            <a className="mx-2" target="_blank" rel="noreferrer" href="https://twitter.com/moonwolfnear">
              <i className="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default App;
